import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import {
  getSpeculationSettings,
  updateSpeculationSettings,
} from '../../../../applications-list/core/_requests'

import {
  SpeculationSettings,
  SpeculationMode,
  SpeculationEagerness,
} from '../../../../applications-list/core/_models'

const SpeculationAPI = () => {
  const {applicationId} = useParams()
  const [isUpdating, setIsUpdating] = useState(false)

  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false)
  const [initialValues, setInitialValues] = useState<SpeculationSettings>()

  const speculationModeOptions = [
    {label: 'Prefetch', value: SpeculationMode.PREFETCH},
    {label: 'Prerender', value: SpeculationMode.PRERENDER},
  ]

  const eagernessOptions = [
    {label: 'Conservative (typically on click)', value: SpeculationEagerness.CONSERVATIVE},
    {label: 'Moderate (typically on hover)', value: SpeculationEagerness.MODERATE},
    {label: 'Eager (on slightest suggestion)', value: SpeculationEagerness.EAGER},
  ]

  const formik = useFormik<SpeculationSettings>({
    initialValues: {
      state: false,
      mode: SpeculationMode.PREFETCH,
      eagerness: SpeculationEagerness.MODERATE,
    },

    onSubmit: async (values) => {
      try {
        setIsUpdating(true)
        const response = await updateSpeculationSettings(applicationId!, values)

        if (response) {
          notifySuccess('Speculation settings updated successfully!')
          formik.resetForm({values})
          setInitialValues(values)
        } else {
          notifyError('Something went wrong!')
        }
      } catch (error) {
        notifyError('Failed to update speculation settings!')
      } finally {
        setIsUpdating(false)
      }
    },
  })

  const fetchSpeculationSettings = async () => {
    try {
      setIsUpdating(true)
      const response = await getSpeculationSettings(applicationId!)

      formik.setValues(response)
      setInitialValues(response)
    } catch (error) {
      notifyError('Failed to fetch speculation settings!')
    } finally {
      setIsUpdating(false)
    }
  }

  useEffect(() => {
    fetchSpeculationSettings()
  }, [applicationId])

  useEffect(() => {
    if (
      initialValues &&
      (formik.values.state !== initialValues.state ||
        (formik.values.state &&
          (formik.values.mode !== initialValues.mode ||
            formik.values.eagerness !== initialValues.eagerness)))
    ) {
      setIsSaveButtonActive(true)
    } else {
      setIsSaveButtonActive(false)
    }

    if (initialValues && !formik.values.state) {
      formik.values.mode = initialValues.mode
      formik.values.eagerness = initialValues.eagerness
    }
  }, [formik.values, initialValues])

  return (
    <div className='container-fluid'>
      <TabHeader heading='Speculation API' />
      <TabBody>
        <form onSubmit={formik.handleSubmit}>
          <div className='row align-items-center mb-8'>
            <div className='form-label col-lg-3'>Enable Speculative API:</div>
            <div className='col-lg-4 form-check form-check-solid form-switch fv-row'>
              <input
                className='form-check-input w-45px h-25px'
                type='checkbox'
                id='state'
                checked={formik.values.state}
                onChange={() => formik.setFieldValue('state', !formik.values.state)}
                style={{
                  backgroundColor: formik.values.state ? 'green' : 'grey',
                  borderColor: formik.values.state ? 'green' : 'grey',
                }}
              />
            </div>
          </div>

          <div className={`rounded-3 border px-4 py-6 mb-4`}>
            <div className='mb-10'>
              <h5 className='fw-bolder d-flex align-items-center'>Speculation Mode</h5>
              <p className='text-muted'>
                Prerendering will lead to faster load times than prefetching. However, in case of
                interactive content, prefetching may be a safer choice.
              </p>
              <div className='ms-4 mt-3'>
                <div className='form-check d-flex flex-column'>
                  {speculationModeOptions.map((option) => (
                    <div
                      className='form-check-label mb-4 d-flex align-items-center'
                      key={option.value}
                    >
                      <input
                        type='radio'
                        className='form-check-input me-2'
                        checked={formik.values.mode == option.value}
                        onChange={() => formik.setFieldValue('mode', option.value)}
                        disabled={!formik.values.state}
                      />
                      {option.label}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <h5 className='fw-bolder d-flex align-items-center'>Eagerness</h5>
              <p className='text-muted'>
                The eagerness setting defines the heuristics based on which the loading is
                triggered.
              </p>
              <div className='ms-4 mt-3'>
                <div className='form-check d-flex flex-column'>
                  {eagernessOptions.map((option) => (
                    <div
                      className='form-check-label mb-4 d-flex align-items-center'
                      key={option.value}
                    >
                      <input
                        type='radio'
                        className='form-check-input me-2'
                        checked={formik.values.eagerness == option.value}
                        onChange={() => formik.setFieldValue('eagerness', option.value)}
                        disabled={!formik.values.state}
                      />
                      {option.label}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center my-5'>
            <button
              type='submit'
              className={`btn ${isUpdating ? 'btn-warning' : 'btn-primary'} me-10 p-3`}
              disabled={isUpdating || !isSaveButtonActive}
            >
              <span className='indicator-label'>
                {isUpdating ? 'Please wait . . .' : 'Apply Settings'}
              </span>
              {isUpdating && (
                <span className='indicator-progress'>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </TabBody>
    </div>
  )
}

export default SpeculationAPI
