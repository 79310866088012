export function ZohoDoc() {
  return (
    <div className='card mb-5 mb-xl-10'>
      <iframe
        src='https://learn.zohopublic.in/external/manual/cloudphant-knowledge-base/article/what-is-cloudphant?p=bf8be37999e640b9a380362c5c30ac28a8f01f30dd00e4a5c5407c821876cb9b'
        title='Cloudphant Knowledge Base'
        className='min-h-800px'
      ></iframe>
    </div>
  )
}
