import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {MasterLayout} from '../../../_metronic/layout/MasterLayout'
import {UserGuide} from './user-guide/UserGuide'
import {useAuth} from '../auth'

const docsBreadCrumbs: Array<PageLink> = [
  {
    title: 'User Guide',
    path: '/docs/user-guide',
    isSeparator: false,
    isActive: false,
  },
]

const DocsPage = () => {
  const {currentUser} = useAuth()

  return (
    <Routes>
      <Route element={currentUser ? <MasterLayout /> : <Outlet />}>
        <Route
          path='user-guide'
          element={
            <>
              <PageTitle breadcrumbs={docsBreadCrumbs}>User Guide</PageTitle>
              <UserGuide />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/docs/user-guide' />} />
    </Routes>
  )
}

export default DocsPage
