import axios from 'axios'
import {ApiResponse, AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/v1/auth/login`
export const REGISTER_URL = `${API_URL}/v1/user`
export const FORGOT_PASSWORD_URL = `${API_URL}/v1/user/password-reset-request`
export const RESET_PASSWORD_URL = `${API_URL}/v1/user/password-reset`
export const GET_USER = `${API_URL}/v1/user`
export const RECAPTCHA_URL = `${API_URL}/v1/auth/verify-recaptcha`

// Server should return AuthModel
export async function login(email: string, password: string, recaptchaToken: string) {
  const response = await axios.post<ApiResponse>(LOGIN_URL, {
    email,
    password,
    recaptchaToken,
  })

  if (response.data?.code) {
    throw new Error(response.data?.message)
  } else {
    const authObj: AuthModel = response.data.data

    return authObj
  }
}

// Server should return AuthModel
export async function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  recaptchaToken: string
) {
  const response = await axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    recaptchaToken,
  })

  if (response.data?.code) {
    throw new Error(response.data?.message)
  } else {
    return response.data.data
  }
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function forgotPassword(email: string, recaptchaToken: string) {
  const response = await axios.post<ApiResponse>(FORGOT_PASSWORD_URL, {
    email,
    recaptchaToken,
  })
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

export async function resetPassword(
  email: string,
  resetToken: string,
  password: string,
  rePassword: string
) {
  const response = await axios.post<ApiResponse>(
    `${RESET_PASSWORD_URL}/?email=${email}&token=${resetToken}`,
    {
      password: password,
      rePassword: rePassword,
    }
  )
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

// returns user
export async function getUser(token: string) {
  const response = await axios.get<ApiResponse>(GET_USER, {
    headers: {Authorization: `Bearer ${token}`},
  })

  if (response.data?.code) {
    throw new Error(response.data?.message)
  } else {
    const user: UserModel = response.data.data

    return user
  }
}
