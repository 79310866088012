import {useEffect, useState} from 'react'
import {CriticalCSSState} from '../../../../applications-list/core/_models'
import {useFormik} from 'formik'
import {notifyError, notifyProgress, notifySuccess} from '../../../../../../utils/NotifyUtils'
import {
  updateCriticalCSS,
  getCriticalCSS,
  generateCriticalCSS,
} from '../../../../applications-list/core/_requests'
import {useParams} from 'react-router-dom'

const CriticalCSS = () => {
  const {applicationId} = useParams()

  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false)
  const [initialValues, setInitialValues] = useState<CriticalCSSState>()

  const formik = useFormik<CriticalCSSState>({
    initialValues: {
      state: false,
      css: '',
    },

    onSubmit: async (values) => {
      try {
        setIsUpdating(true)
        const toastId = notifyProgress('Applying Critical CSS settings...')

        const response = await updateCriticalCSS(applicationId!, values)

        if (response) {
          notifySuccess('Critical CSS settings updated successfully!', toastId)
          formik.resetForm({values})
          setInitialValues(values)
        } else {
          notifyError('Something went wrong!', toastId)
        }
      } catch (error) {
        notifyError('Failed to apply Critical CSS settings!')
      } finally {
        setIsUpdating(false)
      }
    },
  })

  const fetchCriticalCSSSettings = async () => {
    try {
      setIsUpdating(true)
      const response = await getCriticalCSS(applicationId!)

      formik.setValues(response)
      setInitialValues(response)
    } catch (error) {
      notifyError('Fetch Critical CSS state failed!')
    } finally {
      setIsUpdating(false)
    }
  }

  const handleGenerateCriticalCSS = async (e: React.MouseEvent) => {
    e.preventDefault()
    if (!formik.values.state) return

    const toastId = notifyProgress('Generating Critical CSS in progess...')
    setIsUpdating(true)

    try {
      const response = await generateCriticalCSS(applicationId!)
      formik.setFieldValue('css', response)
      setIsSaveButtonActive(true)
      notifySuccess('Critical CSS generated successfully!', toastId)
    } catch (error) {
      notifyError('Failed to generate Critical CSS!', toastId)
    } finally {
      setIsUpdating(false)
    }
  }

  useEffect(() => {
    fetchCriticalCSSSettings()
  }, [applicationId])

  useEffect(() => {
    if (
      initialValues &&
      (formik.values.state !== initialValues.state ||
        (formik.values.state && formik.values.css !== initialValues.css))
    ) {
      setIsSaveButtonActive(true)
    } else {
      setIsSaveButtonActive(false)
    }

    if (initialValues && !formik.values.state) {
      formik.values.css = initialValues.css
    }
  }, [formik.values, initialValues])

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='rounded-3 border p-4 mb-4'>
        <div className='row mb-4'>
          <h5 className='fw-bolder d-flex align-items-center'>Critical CSS</h5>
          <p className='text-muted'>
            Manage Critical CSS to eliminate render-blocking CSS to improve FCP Core Web Vitals
            score.
          </p>
          <hr className='m-2' style={{color: 'lightgrey'}}></hr>
          <div className='row align-items-center mb-4'>
            <div className='form-label col-lg-3'>Enable Critical CSS:</div>
            <div className='col-lg-4 form-check form-check-solid form-switch fv-row'>
              <input
                className='form-check-input w-45px h-25px'
                type='checkbox'
                id='state'
                checked={formik.values.state}
                onChange={() => formik.setFieldValue('state', !formik.values.state)}
                style={{
                  backgroundColor: formik.values.state ? 'green' : 'grey',
                  borderColor: formik.values.state ? 'green' : 'grey',
                }}
              />
            </div>
          </div>
        </div>

        <div className='form-group mb-3'>
          <textarea
            className='form-control'
            rows={10}
            placeholder='Paste Critical CSS here...'
            value={formik.values.css}
            onChange={(e) => {
              formik.setFieldValue('css', e.target.value)
              setIsSaveButtonActive(
                initialValues ? initialValues.css !== e.target.value : e.target.value !== ''
              )
            }}
            disabled={!formik.values.state}
            style={{resize: 'vertical'}}
          />
        </div>

        <div className='d-flex justify-content-between'>
          <span>
            <button
              type='button'
              className='text-primary'
              onClick={handleGenerateCriticalCSS}
              disabled={!formik.values.state}
              style={{
                pointerEvents: formik.values.state ? 'auto' : 'none',
                opacity: formik.values.state ? 1 : 0.6,
                marginRight: '20px',
                background: 'none',
                border: 'none',
                cursor: formik.values.state ? 'pointer' : 'default',
              }}
            >
              {isUpdating ? 'Generating...' : 'Generate CCSS'}
            </button>

            <a
              href='https://codebeautify.org/css-beautify-minify'
              target='_blank'
              style={{opacity: formik.values.state ? 1 : 0.6}}
            >
              Prettify CSS
            </a>
          </span>

          <button
            type='submit'
            className={`btn ${isUpdating ? 'btn-warning' : 'btn-primary'}`}
            disabled={
              isUpdating ||
              !isSaveButtonActive ||
              (formik.values.state && !formik.values.css.trim())
            }
          >
            <span className='indicator-label'>
              {isUpdating ? 'Please wait . . .' : 'Apply Settings'}
            </span>
            {isUpdating && (
              <span className='indicator-progress'>
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export default CriticalCSS
