import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {
  getWebPOptimization,
  updateWebPOptimization,
} from '../../../../applications-list/core/_requests'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import {ApplicationWebPOptimization} from '../../../../applications-list/core/_models'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const WebP: React.FC = () => {
  const {applicationId} = useParams<{applicationId: string}>()
  const [isUpdating, setIsUpdating] = useState(false)
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false)
  const [initialValues, setInitialValues] = useState<ApplicationWebPOptimization | null>(null)

  const formik = useFormik<ApplicationWebPOptimization>({
    initialValues: {
      state: false,
      quality: 50,
      is_metadata: false,
      webp_path: '',
    },
    onSubmit: async (values) => {
      setIsUpdating(true)
      updateWebPOptimization(applicationId ?? '', values).then((response) => {
        if (response) {
          notifySuccess('WebP Optimization updated')
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else {
          notifyError('Something went wrong')
        }
        setIsUpdating(false)
      })
    },
  })

  useEffect(() => {
    getWebPOptimization(applicationId!).then((resp) => {
      formik.setValues({
        state: resp!.state,
        quality: resp!.quality,
        is_metadata: resp!.is_metadata,
        webp_path: resp!.webp_path || '',
      })
      setInitialValues({
        state: resp!.state,
        quality: resp!.quality,
        is_metadata: resp!.is_metadata,
        webp_path: resp!.webp_path,
      })
    })
  }, [applicationId])

  useEffect(() => {
    if (
      initialValues &&
      (formik.values.state !== initialValues.state ||
        (formik.values.state &&
          (formik.values.quality !== initialValues.quality ||
            formik.values.is_metadata !== initialValues.is_metadata ||
            formik.values.webp_path !== initialValues.webp_path)))
    ) {
      setIsSaveButtonActive(true)
    } else {
      setIsSaveButtonActive(false)
    }

    if (initialValues && !formik.values.state) {
      formik.values.quality = initialValues.quality
      formik.values.is_metadata = initialValues.is_metadata
      formik.values.webp_path = initialValues.webp_path
    }
  }, [formik.values, initialValues])

  return (
    <div className='container-fluid' id='webp_optimization_tab'>
      <TabHeader heading='WebP Optimization' />
      <TabBody>
        <div className='alert alert-primary d-flex align-items-center p-4 mb-8'>
          <div className='d-flex flex-column'>
            <span>
              WebP Path is the path relative to the wp-content directory. So if you use
              webp-uploads, the webp image path will be {'{root}'}/wp-content/webp-uploads/
              {'{relative image path in uploads}'}. DO NOT use slashes (/) in the path name.
            </span>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='row align-items-center mb-4'>
            <div className='form-label col-lg-3'>WebP State:</div>
            <div className='col-lg-4 form-check form-check-solid form-switch fv-row'>
              <input
                className='form-check-input w-45px h-25px'
                type='checkbox'
                id='state'
                checked={formik.values.state}
                onChange={() => formik.setFieldValue('state', !formik.values.state)}
                style={{
                  backgroundColor: formik.values.state ? 'green' : 'grey',
                  borderColor: formik.values.state ? 'green' : 'grey',
                }}
              />
            </div>
          </div>

          <div className='separator border-secondary my-4'></div>

          <div className='row align-items-center mb-4'>
            <div className='form-label col-lg-3'>Quality Slider:</div>
            <div className='col-lg-4'>
              <input
                type='range'
                className='form-range'
                id='quality'
                min={10}
                max={100}
                value={formik.values.quality}
                onChange={(e) => formik.setFieldValue('quality', +e.target.value)}
                disabled={!formik.values.state}
              />
              <div className='d-flex justify-content-between'>
                <span>10</span>
                <span>100</span>
              </div>
              <div className='d-flex justify-content-between mt-2'>
                <span>Current Value: {formik.values.quality}</span>
              </div>
            </div>
          </div>

          <div className='separator border-secondary my-4'></div>

          <div className='row align-items-center mb-4'>
            <div className='form-label col-lg-3'>WebP Path:</div>
            <div className='col-lg-4'>
              <input
                type='text'
                className='form-control'
                id='webp_path'
                value={formik.values.webp_path}
                onChange={(e) => formik.setFieldValue('webp_path', e.target.value)}
                disabled={!formik.values.state}
                placeholder='Enter WebP path'
              />
            </div>
          </div>

          <div className='separator border-secondary my-4'></div>

          <div className='row align-items-center mb-4'>
            <div className='form-label col-lg-3'>Metadata:</div>
            <div className='col-lg-4 form-check form-check-solid form-switch fv-row'>
              <input
                className='form-check-input w-45px h-25px'
                type='checkbox'
                id='isMetadata'
                checked={formik.values.is_metadata}
                onChange={() => formik.setFieldValue('is_metadata', !formik.values.is_metadata)}
                disabled={!formik.values.state}
                style={{
                  backgroundColor: formik.values.is_metadata ? 'green' : 'grey',
                  borderColor: formik.values.is_metadata ? 'green' : 'grey',
                }}
              />
            </div>
          </div>

          <div className='d-flex align-items-center my-5'>
            <button
              type='submit'
              className={`btn ${isUpdating ? 'btn-warning' : 'btn-primary'} me-10 p-3`}
              disabled={isUpdating || !isSaveButtonActive}
            >
              <span className='indicator-label'>
                {isUpdating ? 'Please wait . . .' : 'Apply Settings'}
              </span>
              {isUpdating && (
                <span className='indicator-progress'>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </TabBody>
    </div>
  )
}

export default WebP
