/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid max-h-100'>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='mt-5 d-md-none'>
            <Link to='/'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/custom-2.png')} className='h-55px' />
            </Link>
            <hr className='mt-10' style={{color: '#808080'}}></hr>
          </div>
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
        </div>

        <div className='d-flex flex-center flex-wrap px-5'>
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='https://cloudphant.com/terms-conditions/' className='px-4' target='_blank'>
              Terms
            </a>

            <a href='https://cloudphant.com/privacy-policy/' className='px-4' target='_blank'>
              Privacy
            </a>

            <a href='https://cloudphant.com/pricing' className='px-4' target='_blank'>
              Plans
            </a>

            <a href='https://cloudphant.com/' className='px-4' target='_blank'>
              Contact Us
            </a>

            <Link to='/docs/user-guide' className='px-4' target='_blank'>
              Documentation
            </Link>
          </div>
        </div>
      </div>
      <div
        className='d-none d-md-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
        <div className='d-flex flex-column flex-lg-row-fluid flex-center py-15 px-5 px-md-15 w-100'>
          <Link to='/' style={{marginBottom: '35px'}}>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/custom-4.png')} className='h-40px' />
          </Link>
          <h1 className='text-white fw-bolder text-center mb-7' style={{fontSize: '30px'}}>
            ⁠Sign-up to get $15 free cloud credits
          </h1>
          <p className='text-white text-center mb-0' style={{fontSize: '20px'}}>
            Run exceptional WordPress and WooCommerce
          </p>
          <p className='text-white text-center mb-5' style={{fontSize: '20px'}}>
            experiences on Cloud
          </p>
          <img
            className='mx-auto w-325px w-md-60 w-xl-475px mb-20 mb-lg-20'
            src={toAbsoluteUrl('/media/auth/landing-page-main-img.png')}
            alt=''
          />
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}
