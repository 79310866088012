import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import ConfirmSuccessModal from '../../../../../../utils/ConfirmSuccessModal'
import {purgeApplicationOPCache} from '../../../../applications-list/core/_requests'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const OPCache = () => {
  const {applicationId} = useParams()

  const [isUpdating, setIsUpdating] = useState(false)
  const [isPurgeCacheConfirmationVisible, setIsPurgeCacheConfirmationVisible] = useState(false)

  const handleConfirmPurgeCache = () => {
    setIsPurgeCacheConfirmationVisible(false)
    setIsUpdating(true)

    purgeApplicationOPCache(applicationId ?? '').then((response) => {
      if (response) {
        notifySuccess('OPCache purge successful')
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        notifyError('Something went wrong')
      }
      setIsUpdating(false)
    })
  }

  return (
    <div className='container-fluid' id='op_cache_tab'>
      <TabHeader heading='PHP OPCache' />
      <TabBody>
        <div className='rounded-3 border p-4 mb-4 d-flex'>
          <div className='me-4 flex-grow-1' style={{flex: '1'}}>
            <h5 className='fw-bolder d-flex align-items-center'>PHP OPCache Purge</h5>
            <p className='text-muted'>
              Manually purge the PHP OPCache to ensure the latest version of PHP files are compiled
              and served to visitors.
            </p>
          </div>
          <div
            className='d-flex border-start flex-column justify-content-center align-items-center'
            style={{flex: '0 0 20%'}}
          >
            <div className='d-flex flex-column'>
              <button
                type='button'
                className={`btn btn-primary btn-sm p-3`}
                onClick={() => setIsPurgeCacheConfirmationVisible(true)}
                disabled={isUpdating}
              >
                <span className='indicator-label'>
                  {isUpdating ? 'Please wait . . .' : 'Purge Cache'}
                </span>
                {isUpdating && (
                  <span className='indicator-progress'>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </TabBody>

      {isPurgeCacheConfirmationVisible && (
        <ConfirmSuccessModal
          setIsOpen={setIsPurgeCacheConfirmationVisible}
          title='Are you sure?'
          btnTitle='Purge Cache'
          content='Proceed with the opcache purge?'
          successFunction={handleConfirmPurgeCache}
        />
      )}
    </div>
  )
}

export default OPCache
