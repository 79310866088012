import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {notifyError} from '../../../../../../utils/NotifyUtils'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'
import FeatureToggle from '../../../../../common/components/FeatureToggle'
import FeatureToggleHandler from '../../../../../../utils/FeatureToggleHandler'
import {
  getPreloadLCPState,
  updatePreloadLCPState,
} from '../../../../applications-list/core/_requests'

const PreloadLCP = () => {
  const {applicationId} = useParams()
  const [preloadState, setPreloadState] = useState<boolean>(false)
  const [preloadLoading, setPreloadLoading] = useState<boolean>(false)

  const fetchPreloadStatus = async () => {
    try {
      const response = await getPreloadLCPState(applicationId!)
      setPreloadState(response?.state ?? false)
    } catch (error) {
      notifyError('Failed to fetch preload state!')
    }
  }

  // Wrapper function to match FeatureToggleHandler's expected signature
  const updatePreloadState = async (id: string, state: {state: boolean}) => {
    const response = await updatePreloadLCPState(id, state.state)
    return response ?? false
  }

  useEffect(() => {
    fetchPreloadStatus()
  }, [applicationId])

  return (
    <div className='container-fluid' id='preload_lcp_tab'>
      <TabHeader heading='Preload LCP' />
      <TabBody>
        <div className='d-flex flex-column gap-4'>
          <FeatureToggle
            title='Preload LCP'
            description='Preloads LCP (Largest Contentful Paint) to improve Core Web Vitals score.'
            enabled={preloadState}
            onToggle={() =>
              FeatureToggleHandler(
                applicationId!,
                preloadState,
                updatePreloadState,
                setPreloadState,
                setPreloadLoading,
                `Preload LCP ${preloadState ? 'disabled' : 'enabled'} successfully`,
                'Failed to update preload state'
              )
            }
            loading={preloadLoading}
          />
        </div>
      </TabBody>
    </div>
  )
}

export default PreloadLCP
