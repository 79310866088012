import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {ApplicationObjectCache} from '../../../../applications-list/core/_models'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import ConfirmSuccessModal from '../../../../../../utils/ConfirmSuccessModal'
import {
  getObjectCacheStateByApplication,
  purgeApplicationObjectCache,
  updateApplicationObjectCacheState,
} from '../../../../applications-list/core/_requests'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'
import FeatureToggle from '../../../../../common/components/FeatureToggle'

const ObjectCache = () => {
  const {applicationId} = useParams()

  const [objectCacheInfo, setObjectCacheInfo] = useState<ApplicationObjectCache | undefined>({
    state: false,
  })
  const [isUpdating, setIsUpdating] = useState(false)
  const [isPurgeCacheConfirmationVisible, setIsPurgeCacheConfirmationVisible] = useState(false)

  const fetchObjectCacheState = async () => {
    try {
      if (!applicationId) {
        console.error('Application ID is missing')
        return
      }

      const response = await getObjectCacheStateByApplication(applicationId)
      if (response) {
        setObjectCacheInfo(response)
      } else {
        notifyError('Failed to fetch object cache state')
      }
    } catch (error) {
      console.error('Error fetching object cache state:', error)
      notifyError('Failed to fetch object cache state')
    }
  }

  useEffect(() => {
    fetchObjectCacheState()
  }, [applicationId])

  const handleToggleChange = async () => {
    try {
      if (!objectCacheInfo || !applicationId) {
        console.error('Required data is missing')
        return
      }

      setIsUpdating(true)
      const newState = !objectCacheInfo.state

      const response = await updateApplicationObjectCacheState(applicationId, newState)

      if (response) {
        setObjectCacheInfo((prev) => {
          if (!prev) return {state: newState}
          return {...prev, state: newState}
        })
        notifySuccess(`Object cache ${newState ? 'enabled' : 'disabled'} successfully`)

        // Delay reload to allow notification to be seen
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        throw new Error('Failed to update object cache state')
      }
    } catch (error) {
      console.error('Error updating object cache state:', error)
      notifyError('Failed to update object cache state')
      // Refresh state from server in case of error
      await fetchObjectCacheState()
    } finally {
      setIsUpdating(false)
    }
  }

  const handleConfirmPurgeCache = async () => {
    try {
      if (!applicationId) {
        console.error('Application ID is missing')
        return
      }

      setIsPurgeCacheConfirmationVisible(false)
      setIsUpdating(true)

      const response = await purgeApplicationObjectCache(applicationId)

      if (response) {
        notifySuccess('Cache purge successful')
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        throw new Error('Cache purge failed')
      }
    } catch (error) {
      console.error('Error purging cache:', error)
      notifyError('Failed to purge cache')
    } finally {
      setIsUpdating(false)
    }
  }

  const isCacheEnabled = objectCacheInfo?.state ?? false

  return (
    <div className='container-fluid' id='object_cache_tab'>
      <TabHeader heading='Object Cache' />
      <TabBody>
        <div className='d-flex flex-column'>
          <FeatureToggle
            title='Object Cache Status'
            description='Enable or disable object caching to improve application performance by storing frequently accessed data.'
            enabled={isCacheEnabled}
            onToggle={handleToggleChange}
            loading={isUpdating}
          />

          <div className='rounded-3 border p-4 mb-4 d-flex'>
            <div className='me-4 flex-grow-1' style={{flex: '1'}}>
              <h5 className='fw-bolder d-flex align-items-center'>Purge Cache</h5>
              <p className='text-muted'>
                Manually purge cached objects to ensure the latest version is served to visitors.
              </p>
            </div>
            <div
              className='d-flex border-start flex-column justify-content-center align-items-center'
              style={{flex: '0 0 20%'}}
            >
              <div className='d-flex flex-column'>
                <button
                  type='button'
                  className='btn btn-primary btn-sm p-3'
                  onClick={() => setIsPurgeCacheConfirmationVisible(true)}
                  disabled={!isCacheEnabled || isUpdating}
                >
                  <span className='indicator-label'>
                    {isUpdating ? 'Please wait . . .' : 'Purge Cache'}
                  </span>
                  {isUpdating && (
                    <span className='indicator-progress'>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </TabBody>

      {isPurgeCacheConfirmationVisible && (
        <ConfirmSuccessModal
          setIsOpen={setIsPurgeCacheConfirmationVisible}
          title='Are you sure?'
          btnTitle='Purge Cache'
          content='Proceed with the cache purge?'
          successFunction={handleConfirmPurgeCache}
        />
      )}
    </div>
  )
}

export default ObjectCache
