import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {HTMLMinifyObject} from '../../../../applications-list/core/_models'
import {notifyError} from '../../../../../../utils/NotifyUtils'
import {
  getHTMLMinifyStateByApplication,
  updateApplicationMinifyHTMLState,
} from '../../../../applications-list/core/_requests'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'
import FeatureToggle from '../../../../../common/components/FeatureToggle'
import FeatureToggleHandler from '../../../../../../utils/FeatureToggleHandler'

const HTMLMinify = () => {
  const {applicationId} = useParams()
  const [htmlMinifyState, setHTMLMinifyState] = useState<boolean>(false)
  const [htmlMinifyLoading, setHTMLMinifyLoading] = useState<boolean>(false)

  const fetchHTMLMinifyStatus = async () => {
    try {
      const response = await getHTMLMinifyStateByApplication(applicationId!)
      setHTMLMinifyState(response?.state ?? false)
    } catch (error) {
      notifyError('Failed to fetch HTML minify state!')
    }
  }

  // Wrapper function to match FeatureToggleHandler's expected signature
  const updateHTMLMinifyState = async (id: string, state: {state: boolean}) => {
    const response = await updateApplicationMinifyHTMLState(id, state.state)
    return response ?? false
  }

  useEffect(() => {
    fetchHTMLMinifyStatus()
  }, [applicationId])

  return (
    <div className='container-fluid' id='html_minify_tab'>
      <TabHeader heading='HTML Optimizations' />
      <TabBody>
        <div className='d-flex flex-column gap-4'>
          <FeatureToggle
            title='HTML Minify'
            description='Minifies HTML files to improve page speed.'
            enabled={htmlMinifyState}
            onToggle={() =>
              FeatureToggleHandler(
                applicationId!,
                htmlMinifyState,
                updateHTMLMinifyState,
                setHTMLMinifyState,
                setHTMLMinifyLoading,
                `HTML minification ${htmlMinifyState ? 'disabled' : 'enabled'} successfully`,
                'Failed to update HTML minification state'
              )
            }
            loading={htmlMinifyLoading}
          />
        </div>
      </TabBody>
    </div>
  )
}

export default HTMLMinify
