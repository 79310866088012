import {useState, useEffect, Dispatch, SetStateAction} from 'react'
import {Link, useParams} from 'react-router-dom'
import {ApplicationStaging, StagingStatus} from '../../../applications-list/core/_models'
import {
  getStagingStatusByApplication,
  updateApplicationStagingStatus,
  updateApplicationSyncStatus,
} from '../../../applications-list/core/_requests'
import {notifyError, notifySuccess, notifyProgress} from '../../../../../utils/NotifyUtils'
import ConfirmSuccessModal from '../../../../../utils/ConfirmSuccessModal'
import FeatureToggle from '../../../../common/components/FeatureToggle'
import FeatureToggleHandler from '../../../../../utils/FeatureToggleHandler'

const Staging: React.FC = () => {
  const {applicationId} = useParams()

  const [stagingInfo, setStagingInfo] = useState<ApplicationStaging | undefined>({
    staging_state: false,
    staging_status: StagingStatus.SYNCING_COMPLETED,
    staging_application: {
      application_id: '',
    },
  })
  const [isUpdating, setIsUpdating] = useState(false)
  const [isSyncStagingConfirmationVisible, setIsSyncStagingConfirmationVisible] = useState(false)

  const fetchStagingStatus = async () => {
    try {
      const response = await getStagingStatusByApplication(applicationId ?? '')
      setStagingInfo(response)
    } catch (error) {
      notifyError('Failed to fetch staging status!')
    }
  }

  useEffect(() => {
    fetchStagingStatus()
  }, [])

  useEffect(() => {
    if (stagingInfo!.staging_status?.includes('_IN_PROGRESS')) {
      const pollInterval = setInterval(fetchStagingStatus, 5000)
      return () => clearInterval(pollInterval)
    }
  }, [stagingInfo!.staging_status])

  const updateStagingStatus = async (id: string, state: {state: boolean}) => {
    const response = await updateApplicationStagingStatus(id, state.state)
    return !!response
  }

  const updateStagingState: Dispatch<SetStateAction<boolean>> = (value) => {
    const newState =
      typeof value === 'function' ? value(stagingInfo?.staging_state ?? false) : value
    setStagingInfo((prev) => (prev ? {...prev, staging_state: newState} : undefined))
  }

  const handleConfirmSyncStaging = async () => {
    setIsSyncStagingConfirmationVisible(false)
    const toastId = notifyProgress('Syncing Process in progress...')
    try {
      const response = await updateApplicationSyncStatus(applicationId ?? '')
      if (response) {
        notifySuccess('Syncing Process started successfully', toastId)
        fetchStagingStatus()
      }
    } catch (error) {
      notifyError('Syncing Process failed', toastId)
    }
  }

  return (
    <div className='card mb-3 mb-xl-10' id='staging_tab'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Staging</h3>
        </div>
      </div>

      <div className='card-body bg-white'>
        <div className='py-4 px-9'>
          <div className='d-flex flex-column'>
            {stagingInfo!.staging_state && stagingInfo!.staging_application?.application_id && (
              <div className='alert alert-warning'>
                Go to staging website:{' '}
                <Link
                  to={`/application/${
                    stagingInfo!.staging_application?.application_id
                  }/access-details`}
                >
                  {stagingInfo!.staging_application?.application_id}
                </Link>
              </div>
            )}

            {stagingInfo!.staging_status?.includes('_IN_PROGRESS') ? (
              <div className='alert alert-warning'>
                {stagingInfo!.staging_status?.includes('UPDATION')
                  ? `* Staging status update is in progress...`
                  : `* Staging sync is in progress...`}
              </div>
            ) : (
              <>
                <FeatureToggle
                  title='Staging Status'
                  description='Enable or disable staging environment for this application.'
                  enabled={stagingInfo?.staging_state ?? false}
                  onToggle={() =>
                    FeatureToggleHandler(
                      applicationId!,
                      stagingInfo?.staging_state ?? false,
                      updateStagingStatus,
                      updateStagingState,
                      setIsUpdating,
                      `Staging environment ${
                        stagingInfo?.staging_state ? 'disabled' : 'enabled'
                      } successfully`,
                      'Toggling staging environment failed'
                    )
                  }
                  loading={isUpdating}
                />

                <div className='rounded-3 border p-4 mb-4 d-flex'>
                  <div className='me-4 flex-grow-1' style={{flex: '1'}}>
                    <h5 className='fw-bolder d-flex align-items-center'>Sync Staging</h5>
                    <p className='text-muted'>
                      Sync staging will sync live website to staging website i.e after this your
                      staging will be exactly like the live website.
                    </p>
                  </div>
                  <div
                    className='d-flex border-start flex-column justify-content-center align-items-center'
                    style={{flex: '0 0 20%'}}
                  >
                    <div className='d-flex flex-column'>
                      <button
                        className='btn btn-primary btn-sm p-3'
                        onClick={() => setIsSyncStagingConfirmationVisible(true)}
                        disabled={!stagingInfo?.staging_state || isUpdating}
                      >
                        Sync Staging
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {isSyncStagingConfirmationVisible && (
              <ConfirmSuccessModal
                setIsOpen={setIsSyncStagingConfirmationVisible}
                title='Are you sure?'
                btnTitle='Sync'
                content='Staging will be synced with the live website, and your changes will be erased.'
                successFunction={handleConfirmSyncStaging}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Staging
